var app = angular.module("ElancoPortal", ['wu.masonry', 'ngAnimate', 'ngSanitize', 
'hc.marked', 'ui.bootstrap','ngSanitize', 'ngCsv','angular.filter','ngFileSaver','ngRoute']);

app.config(['markedProvider', function (markedProvider) {
  markedProvider.setOptions({gfm: true});
}]);

app.config(['$compileProvider',
  function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|tel|mailto):/);
  }
]);

app.config(['$locationProvider',
  function($locationProvider) {
  	$locationProvider.html5Mode({
		enabled: true,
		requireBase: false,
		rewriteLinks: false
	})
  }
]);
