(function () {

    "use strict"

    app.controller('footerController', footerController)

    footerController.$inject = ['$scope', '$http', '$filter']

    function footerController($scope, $http, $filter) {
        var _this = this
        this.footerShared = null
        this.footerSite = null

        this.getFooterShared = function () {
            var url = "/apiunlogged/getFooterShared"
            $http.get(url)
                .then(function (dataFromServer, status, headers, config) {
                    _this.footerShared = dataFromServer.data.dataFromServer
                })
                .catch(function (data, status, headers, config) {
                    console.log("Error retrieving shared footer");
                })
        }

        this.getFooterShared()

        this.getFooterSite = function () {
            var url = "/apiunlogged/getFooterSite"
            $http.get(url)
          
                .then(function (dataFromServer, status, headers, config) {
                    _this.footerSite = dataFromServer.data.dataFromServer
                })
                .catch(function (data, status, headers, config) {
                     console.log("Error retrieving site footer");
                })
        }

        this.getFooterSite()
    }
} ())